import { useEffect, useState, useContext } from "react";
import Player from "./Player";

import { RadioContext } from "../modules/RadioProvider";
import _ from 'lodash';

export default function PlayerBar() {

  const [isVisible, setIsVisible] = useState(true);
  

    const { schedule, nowPlaying, playable, shouldPlay, setShouldPlay } = useContext(RadioContext);

  return (
    isVisible ?<>
    <Player
    playable={playable}
    shouldPlay={shouldPlay}
    setShouldPlay={setShouldPlay}
    />
    </>

      : null
  );
}