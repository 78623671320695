import React, { createContext, useState, useEffect } from "react";
import _ from "lodash";

import Data from "./Data";

export const RadioContext = createContext();

function RadioProvider({ children }){
  const [schedule, setSchedule] = useState([]);

  const [nowPlaying, setNowPlaying] = useState(null);
  const [shouldPlay, setShouldPlay] = useState(false);
  const [playable, setPlayable] = useState([]);
  const [lastShow, setLastShow] = useState(null);
  const playEpisode = (content) => {

    var nowPlaying = { episode: content.episode };
    setPlayable(o => nowPlaying);
    setShouldPlay(true);
//    setIsVisible(true);
  };

  const playLiveStream = () => {
    setPlayable([]);
    setShouldPlay(true);
  }

  // TODO: wait 1 min.. THEN enable subscribing to gun/websocket
  useEffect(() => {
    const handleUpdate = ({json, show}) => {
//            console.log("gun", key, data);
          if (_.isArray(playable) && lastShow !== show){
  //          Data.getOnAirShows(2, [{schedule: data}])
              setPlayable(JSON.parse(json));
              setLastShow(show);
          } else {
//            console.log("not array", playable);
          }

      };
    return Data.subscribe("citr/now", handleUpdate);

  }, [_.isArray(playable)]);

  return (
    <RadioContext.Provider value={{
      schedule, 
      playable, 
      playEpisode, 
      playLiveStream, 
      shouldPlay, 
      setShouldPlay, 
      nowPlaying}}>

      {children}
    </RadioContext.Provider>
  );
};

export default RadioProvider;
