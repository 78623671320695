import { Link } from 'react-router-dom';

export default function Logo({ large, white }) {
  //  var url = large ? "https://via.placeholder.com/38x48" : "https://via.placeholder.com/28x36";

  var style = large ? { height: '48px', width: '38px' } : { height: '36px', width: '28px' };

  var colour = white ? 'white' : 'black';

  return (
    <div className="Logo">
      <Link to="/" style={{backgroundColor:"white", minHeight:"200%", width:"100%"}}>{/*}
        <img src={`/images/citr-logo-${colour}.svg`} alt="logo" style={style} />{*/}
      </Link>
    </div>
  )
}